<template>
    <div class="h-100" @mouseleave="focusout">
       <div class="filter-container">
           <b-dropdown class="filter-dropdown" variant="default" ref="drop">
               <template #button-content>
                   <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
               </template>
               <div>
                   <div class="top-content">
                       <span>Параметры фильтра</span>
                       <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                   </div>
                   <div class="filter-block">
                       <b-form-group label="Область/район">
                           <b-form-select
                               v-model="region"
                               :options="listReg"
                               value-field="code"
                               @change="loadDatas"
                           ></b-form-select>
                       </b-form-group>
                   </div>
                   <div class="filter-block">
                       <b-form-group label="Источник">
                           <b-form-select
                               v-model="curSource"
                               :options="listSrc"
                           ></b-form-select>
                       </b-form-group>
                   </div>
                   <div class="filter-block">
                       <b-form-group label="АБП">
                           <b-form-select
                               v-model="selectedABP"
                               :options="dictCost"
                               @change="selectedPRG = { id: 0, child: [] }"
                           ></b-form-select>
                       </b-form-group>
                   </div>
                   <div class="filter-block">
                       <b-form-group label="Программа(БП)" :disabled="(selectedABP.child == 0)">
                           <b-form-select
                               v-model="selectedPRG"
                               :options="selectedABP.child"
                               @change="selectedPPR = { id: 0 }"
                           ></b-form-select>
                       </b-form-group>
                   </div>
                   <div class="filter-block">
                       <b-form-group label="Подпрограмма (БПП)" :disabled="(selectedPRG.child == 0)">
                           <b-form-select
                               v-model="selectedPPR"
                               :options="selectedPRG.child"
                               @change="selectedPRJ = { id: 0 }"
                           >
                               <template #first>
                                   <b-form-select-option :value="{id: 0}">-- Выбрать подпрограмму --
                                   </b-form-select-option>
                               </template>
                           </b-form-select>
                       </b-form-group>
                   </div>
                   <div class="filter-block">
                       <b-form-group label="Проект" :disabled="!(curSource.id == 3 && (!selectedPRG.pprChild || (selectedPRG.pprChild && selectedPPR.id > 0)))">
                           <b-form-select
                               v-model="selectedPRJ"
                               :options="listProject"
                           >
                               <template #first>
                                   <b-form-select-option :value="{id: 0}">-- Выбрать подпрограмму --
                                   </b-form-select-option>
                               </template>
                           </b-form-select>
                       </b-form-group>
                   </div>
                   <b-button class="filter-add" variant="success"
                             v-show="(selectedPRG.id > 0) && (Object.keys(curSource).length > 0)"
                             @click="addItem">+
                   </b-button>
               </div>
           </b-dropdown>
         <div class="filter-actions">
             <b-button variant="success" @click="saveDatas(null)">Сохранить</b-button>
         </div>
       </div>
        <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                :tbody-tr-class="rowClass"
                :filter="filter.search"
                :filter-included-fields="filter.on"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header
                no-border-collapse
            >
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <template #head(abp)="scope">
                    <div>АБП</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.abp"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(prg)="scope">
                    <div>БП</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.prg"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(ppr)="scope">
                    <div>БПП</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.ppr"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(name_ru)="scope">
                    <div>Наименование</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.name_ru"
                        type="search"
                    ></b-form-input>
                </template>

                <template #cell(action)="data">
                    <b-button v-if="data.item.type == 3" v-model="data.item.open"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>

                    <!-- <b-button style="color:green"
                              v-if="(data.item.source == 3) && ((data.item.type == 4) || (data.item.type == 5))"
                              @click="data.toggleDetails"> {{ data.detailsShowing ? '-' : '+'}}
                    </b-button> -->
                </template>

                <!-- <template #row-details="data">
                    <div>{{ data }}</div>
                    <b-card class="table-container">
                        <b-table
                            :fields="tableFields"
                            :items="data.item.projects"
                            responsive="true"
                            bordered
                            head-variant="light"
                            sticky-header
                            no-border-collapse
                        ></b-table>
                    </b-card>
                </template> -->

                <template #cell(abp)="data">
                    <div v-if="data.item.type == 3">{{ data.value }}</div>
                    <b-button v-if="(data.item.type == 4 && data.item.hasChild)" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type == 4 || (data.item.prj && data.item.ptype == 4)">{{ data.value }}</div>
                    <b-button v-if="(data.item.type == 5 && data.item.hasChild)" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(ppr)="data">
                    <div class="text-right">{{ data.value }}</div>
                </template>
                <template #cell(name_ppi)="data">
                    <b-form-select v-if="data.item.editable && ppi(data.item).length > 0"
                                   size="sm"
                                   class="w-100"
                                   v-model="data.item.name_ppi"
                                   :options="ppi(data.item)"
                                   value-field="id"
                                   @change="editing = true"
                                   >
                    </b-form-select>
                </template>
                <template #cell(budget)="data">
                    <div class="text-right">
                        {{ (isNaN(data.value) || (data.value == 0)) ? null : $n(data.value) }}
                    </div>
                </template>
                <template #cell(ind_value)="data">
                    <template v-if="(data.item.editable)">
                        <b-form-input class="text-right"
                                      :value="data.item.ind_value"
                                      @change="v => data.item.ind_value = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="noAbc"
                                      @keyup="editing = true">
                        </b-form-input>
                    </template>
                </template>
                <template #cell(amount_correct)="data">
                    <template v-if="(data.item.editable && !data.item.hasChild)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.amount_correct)"
                                      :id="data.item.active"
                                      @change="v => data.item.amount_correct = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="noAbc"
                                      @keyup="editing = true">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right" v-if="(data.item.source == 4) && (data.item.type == 0)">
                            {{ $n(data.value) }}
                        </div>
                        <div class="text-right" v-else>
                            {{ (isNaN(data.value) || (data.value == 0)) ? null : $n(data.value) }}
                        </div>
                    </template>
                </template>
                <template #cell(ind_change)="data">
                    <template v-if="(data.item.editable)">
                        <b-form-input class="text-right"
                                      :value="data.item.ind_change"
                                      @change="v => data.item.ind_change = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="noAbc"
                                      @keyup="editing = true">
                        </b-form-input>
                    </template>
                </template>
                <template #cell(amount_obk)="data">
                    <template v-if="(data.item.editable && !data.item.hasChild)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.amount_obk)"
                                      @change="v => data.item.amount_obk = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="noAbc"
                                      @keyup="editing = true">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right" v-if="(data.item.source == 4) && (data.item.type == 0)">
                            {{ $n(data.value) }}
                        </div>
                        <div class="text-right" v-else>
                            {{ (isNaN(data.value) || (data.value == 0)) ? null : $n(data.value) }}
                        </div>
                    </template>
                </template>
                <template #cell(note)="data">
                    <template v-if="(data.item.editable)">
                        <textarea style="height: 100%"
                                  cols="50"
                                  v-model="data.item.note"
                                  @change="editing = true">
                        </textarea>
                    </template>
                </template>
                <template #cell(more)="data">
                    <b-button v-if="(data.item.type > 0)" @click="deleteItem(data.item)">
                        <i class="icon icon-close"></i>
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';

export default {
    name: 'CostClarify',
    props: {
        year: Number,

        obl: String,
        reg: String,
        variant: String,

        listReg: Array
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                     class: 'toggle-show'
                },
                {
                    key: 'abp',
                    label: 'АБП',
                     class: 'toggle-show'
                },
                {
                    key: 'prg',
                    label: 'БП',
                     class: 'toggle-show'
                },
                {
                    key: 'ppr',
                    label: 'БПП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'name_ppi',
                    label: 'Наименование показателя результ. программы'
                },
                {
                    key: 'budget',
                    label: 'сумма, тыс.тенге',
                    variant: 'info'
                },
                {
                    key: 'ind_value',
                    label: 'значение показателя'
                },
                {
                    key: 'amount_correct',
                    label: 'Заявлено АБП, в тыс.тенге',
                    variant: 'danger'
                },
                {
                    key: 'ind_change',
                    label: 'Изменение показателя (+/-)'
                },
                {
                    key: 'amount_obk',
                    label: 'Сумма, одобренная ОБК, в тыс.тенге',
                    variant: 'danger'
                },
                {
                    key: 'note',
                    label: 'Примечание'
                },
                {
                    key: 'more',
                    label: ' '
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            calcFlds: [
                'budget',
                'amount_correct',
                'amount_obk'
            ],

            listPPI: [],
            listProject: [],

            dictCost: [],
            ebkFunc: { dict: [], maps: {} },
            selectedABP: { id: 0, child: [] },
            selectedPRG: { id: 0, child: [] },
            selectedPPR: { id: 0 },
            selectedPRJ: { id: 0 },

            listSrc: [],
            saving: { id: 1, name_ru: 'Экономия' },
            optimization: { id: 2, name_ru: 'Оптимизация' },
            distribution: { id: 3, name_ru: 'Доппотребность' },
            redistribution: { id: 4, name_ru: 'Перераспределение' },
            curSource: {},

            budgetForm: [],
            cost: {
                pprMap: new Map(),
                prjMap: new Map()
            },
            remove: {
                mark: false,
                values: [],
                projects: []
            },

            budget: [],
            bdata: {
                abp: new Set(),
                pprMap: new Map(),
                prjMap: new Map()
            },

            filter: {
                abp: null,
                prg: null,
                ppr: null,
                name_ru: null,
                search: null,
                on: ['filter', 'abp', 'prg', 'ppr', 'name_ru']
            },
            open: true,
            editing: false,
            msg: false,
            active: null,
            bar: 0,
            region: null
        };
    },

    created() {
        this.$watch('remove.mark', this.deleteDatas);

        this.$watch('filter.abp', this.filterUpdate);
        this.$watch('filter.prg', this.filterUpdate);
        this.$watch('filter.ppr', this.filterUpdate);
        this.$watch('filter.name_ru', this.filterUpdate);

        this.$watch('selectedPRG.id', this.changeParams);
        this.$watch('selectedPPR.id', this.changeParams);
        this.$watch('reg', this.region = this.reg);
    },

    async mounted() {
        this.listSrc = [
            { value: this.saving },
            { value: this.optimization },
            { value: this.distribution },
            { value: this.redistribution }];
        for (const source of this.listSrc) {
            this.$set(source, 'text', source.value.name_ru);
            this.$set(source.value, 'type', 0);
            this.$set(source.value, 'child', []);
        }
        this.curSource = this.listSrc[0].value;
        await this.loadPPI();
        await this.loadProject();
        this.bar = 10;
        await this.loadBudget();
        this.bar = 20;
        await await this.loadEbkFunc();
        this.bar = 30;
        await await this.loadDatas();
        this.bar = 100;
    },

    beforeUpdate() {
        this.budgetForm.forEach(row => {
            if (row.type == 0) {
                this.$set(row, '_rowVariant', 'danger');
            }
            if (row.type > 0 && row.hasChild) {
                this.$set(row, '_rowVariant', 'info');
            }
            if ((row.type > 4) || (row.type == 4 && !row.pprChild)) {
                this.$set(row, 'editable', true);
            }
            if (row.prj) {
                this.$set(row, '_cellVariants', {
                    ppr: 'secondary',
                    name_ru: 'secondary',
                    name_ppi: 'secondary',
                    budget: 'secondary',
                    ind_value: 'secondary',
                    amount_correct: 'secondary',
                    ind_change: 'secondary',
                    amount_obk: 'secondary',
                    note: 'secondary',
                    more: 'secondary'
                });
                if (row.ptype == 4) {
                    this.$set(row._cellVariants, 'prg', 'secondary');
                }
            }
        });
    },

    methods: {
        addItem() {
            const that = this;

            // формируем выбранные фильтры в виде дерева
            const newABP = Object.assign({}, that.selectedABP);
            that.$set(newABP, 'child', []);
            const newPRG = Object.assign({}, that.selectedPRG);
            that.$set(newPRG, 'child', []);

            let newPPR = null;
            if (that.selectedPPR.id > 0) {
                newPPR = Object.assign({}, that.selectedPPR);

                that.active = that.padLeadingZeros(that.curSource.id, 3) + '.' +
                    that.getRowKey(newPPR, ['abp', 'prg', 'ppr']);
                that.$set(newPPR, 'active', that.active);

                newPRG.child.push(newPPR);
            } else {
                let i = 0;
                for (const ppr of that.selectedPRG.child) {
                    newPPR = Object.assign({}, ppr.value);

                    if (i == 0 && that.active == null) {
                        that.active = that.padLeadingZeros(that.curSource.id, 3) + '.' +
                            that.getRowKey(newPPR, ['abp', 'prg', 'ppr']);
                        that.$set(newPPR, 'active', that.active);
                        i++;
                    }
                    newPRG.child.push(newPPR);
                }
            }
            newABP.child.push(newPRG);

            // в случае источник == доппотребность
            let newPRJ = null;
            if (that.curSource.id == 3) {
                if (that.selectedPRJ.id > 0) {
                    newPRJ = Object.assign({}, that.selectedPRJ);

                    that.active = that.padLeadingZeros(that.curSource.id, 3) + '.' +
                        that.getRowKey(newPRJ, that.selectedPPR.id > 0 ? ['abp', 'prg', 'ppr', 'code'] : ['abp', 'prg', 'code']);
                    that.$set(newPRJ, 'active', that.active);

                    if (that.selectedPPR.id > 0) {
                        that.$set(newPPR, 'child', []);
                        newPPR.child.push(that.addProject(newPPR, newPRJ));
                    } else {
                        newPRG.child.push(that.addProject(newPRG, newPRJ));
                    }
                } else {
                    if (newPPR !== null) {
                        that.$set(newPPR, 'child', []);
                    }
                    let i = 0;
                    for (const prj of that.listProject) {
                        newPRJ = Object.assign({}, prj.value);

                        if (i == 0 && that.active == null) {
                            that.active = that.padLeadingZeros(that.curSource.id, 3) + '.' +
                                that.getRowKey(newPRJ, that.selectedPPR.id > 0 ? ['abp', 'prg', 'ppr', 'code'] : ['abp', 'prg', 'code']);
                            that.$set(newPRJ, 'active', that.active);
                            i++;
                        }
                        if (that.selectedPPR.id > 0) {
                            newPPR.child.push(that.addProject(newPPR, newPRJ));
                        } else {
                            newPRG.child.push(that.addProject(newPRG, newPRJ));
                        }
                    }
                }
            }
            // проверка на существующие элементы в таблице
            const abpExist = that.curSource.child.filter(function (item) {
                if (item.abp == newABP.abp) { return item; }
            });
            if (abpExist.length == 0) { // Если АБП нет => добавляем АБП
                that.curSource.child.push(newABP);
            } else { // Если АБП есть ищем БП
                const prgExist = abpExist[0].child.filter(function (item) {
                    if (item.id == newPRG.id) { return item; }
                });
                if (prgExist.length == 0) { // Если АБП есть + БП нет => добаляем БП
                    abpExist[0].child.push(newPRG);
                } else { // Если АБП + БП есть
                    if (that.curSource.id == 3) { // Доппотребность
                        if (newPRG.pprChild) { // в случае когда БП НЕ последняя ветка в справочнике
                            const pprExist = prgExist[0].child.filter(function (item) {
                                if (item.id == newPPR.id) { return item; }
                            });
                            if (pprExist.length == 0) { // Если АБП + БП есть, НО БПП нет => добавляем БПП
                                prgExist[0].child.push(newPPR);
                            } else {// Если АБП + БП + БПП есть => проверяем наличие Проекта в БПП
                                if (that.selectedPRJ.id > 0) {
                                    if (pprExist[0].hasOwnProperty('child')) {
                                        const prjExist = pprExist[0].child.filter(function (item) {
                                            if (item.id == newPRJ.id) { return item; }
                                        });
                                        if (prjExist.length == 0) { // Если АБП + БП + БПП есть, НО Проекта нет => добавляем Проект
                                            pprExist[0].child.push(that.addProject(pprExist[0], newPRJ));
                                        } else { // если Проект имеется => предупреждаем
                                            that.makeToast('warning', 'Предупреждение',
                                                'Проект \"' + that.selectedPRJ.name_ru + '\" - уже добавлен в таблицу');
                                            return;
                                        }
                                    } else { // если ранее не было Проекта в ветке БПП => добавляем Проект
                                        that.$set(pprExist[0], 'child', []);
                                        pprExist[0].child.push(that.addProject(pprExist[0], newPRJ));
                                    }
                                } else {
                                    let added = false;
                                    let i = 0;
                                    if (pprExist[0].hasOwnProperty('child')) {
                                        for (const prj of newPPR.child) {
                                            const prjExist = pprExist[0].child.filter(function (item) {
                                                if (prj.id == item.id) { return item; }
                                            });
                                            if (prjExist.length == 0) {
                                                const prjNew = Object.assign({}, prj);

                                                if (i == 0 && that.active == null) {
                                                    that.active = that.padLeadingZeros(that.curSource.id, 3) + '.' +
                                                        that.getRowKey(prjNew, ['abp', 'prg', 'code']);
                                                    that.$set(prjNew, 'active', that.active);
                                                    i++;
                                                }
                                                pprExist[0].child.push(that.addProject(pprExist[0], prjNew));
                                                added = true;
                                            }
                                        }
                                        if (!added) {
                                            that.makeToast('warning', 'Предупреждение',
                                                'Подпрограмма \"' + that.selectedPPR.name_ru + '\" - уже добавлена в таблицу');
                                            return;
                                        }
                                    } else {
                                        that.$set(pprExist[0], 'child', [])
                                        for (const prj of that.listProject) {
                                            pprExist[0].child.push(that.addProject(pprExist[0], prj.value));
                                        }
                                    }
                                }
                            }
                        } else { // когда БП == последняя ветка в справочнике
                            if (that.selectedPRJ.id > 0) {
                                const prjExist = prgExist[0].child.filter(function (item) {
                                    if (item.id == newPRJ.id) { return item; }
                                });
                                if (prjExist.length == 0) { // Если АБП + БП есть, НО БПП нет => добавляем БПП
                                    prgExist[0].child.push(that.addProject(prgExist[0], newPRJ));
                                } else { // если Проект имеется => предупреждаем
                                    that.makeToast('warning', 'Предупреждение',
                                        'Проект \"' + that.selectedPRJ.name_ru + '\" - уже добавлен в таблицу');
                                    return;
                                }
                            } else {
                                let added = false;
                                let i = 0;
                                for (const prj of newPRG.child) {
                                    const prjExist = prgExist[0].child.filter(function (item) {
                                        if (prj.id == item.id) { return item; }
                                    });
                                    if (prjExist.length == 0) {
                                        const prjNew = Object.assign({}, prj);

                                        if (i == 0 && that.active == null) {
                                            that.active = that.padLeadingZeros(that.curSource.id, 3) + '.' +
                                                that.getRowKey(prjNew, ['abp', 'prg', 'code']);
                                            that.$set(prjNew, 'active', that.active);
                                            i++;
                                        }
                                        prgExist[0].child.push(that.addProject(prgExist[0], prjNew));
                                        added = true;
                                    }
                                }
                                if (!added) {
                                    that.makeToast('warning', 'Предупреждение',
                                        'Программа \"' + that.selectedPRG.name_ru + '\" - уже добавлена в таблицу');
                                    return;
                                }
                            }
                        }
                    } else { // Источник !== Доппотребность
                        if (that.selectedPPR.id > 0) { // если БПП выбран => проверяем наличие БПП
                            const pprExist = prgExist[0].child.filter(function (item) {
                                if (item.id == newPPR.id) { return item; }
                            });
                            if (pprExist.length == 0) { // Если АБП + БП есть, НО БПП нет => добавляем БПП
                                prgExist[0].child.push(newPPR);
                            } else { // если БПП имеется => предупреждаем
                                that.makeToast('warning', 'Предупреждение',
                                    'Подпрограмма \"' + that.selectedPPR.name_ru + '\" - уже добавлена в таблицу');
                                return;
                            }
                        } else { // если выбран весь БП
                            let added = false;
                            let i = 0;
                            for (const ppr of newPRG.child) {
                                const pprExist = prgExist[0].child.filter(function (item) {
                                    if (ppr.id == item.id) { return item; }
                                });
                                if (pprExist.length == 0) {
                                    const pprNew = Object.assign({}, ppr);

                                    if (i == 0 && that.active == null) {
                                        that.active = that.padLeadingZeros(that.curSource.id, 3) + '.' +
                                            that.getRowKey(pprNew, ['abp', 'prg', 'ppr']);
                                        that.$set(pprNew, 'active', that.active);
                                        i++;
                                    }
                                    prgExist[0].child.push(pprNew);
                                    added = true;
                                }
                            }
                            if (!added) {
                                that.makeToast('warning', 'Предупреждение',
                                    'Программа \"' + that.selectedPRG.name_ru + '\" - уже добавлена в таблицу');
                                return;
                            }
                        }
                    }

                }
            }
            that.prepareDatas();
        }, // добавляет выбранное значение из фильтра в дерево

        addProject(parent, prj) {
            const that = this;

            const item = Object.assign({}, prj);
            that.$set(item, 'prj', true);
            that.$set(item, 'type', 6);
            that.$set(item, 'ptype', parent.type);
            that.$set(item, 'source', parent.source);
            that.$set(item, 'gr', parent.gr);
            that.$set(item, 'pgr', parent.pgr);
            that.$set(item, 'abp', parent.abp);
            that.$set(item, 'prg', parent.prg);
            that.$set(item, 'ppr', parent.ppr);

            that.$set(item, 'filter', item.abp +
                that.padLeadingZeros(item.prg, 3) +
                (parent.type == 5 ? that.padLeadingZeros(item.ppr, 3) : '') +
                item.name_ru);
            return item;
        }, // формирует запись проекта для дерева

        changeParams() {
            this.$emit('changeParams',
                { selABP: this.selectedABP,
                    selPRG: this.selectedPRG,
                    selPPR: this.selectedPPR
                });
        }, // передача параметров фильтра родительскому компоненту

        compareDatas() {
            const that = this;

            const values = {
                pprvals: [],
                prjvals: []
            };
            for (const row of that.cost.pprMap.values()) {
                const val = that.bdata.pprMap.get(that.getRowKey(row, (row.type == 4 ? ['source', 'abp', 'prg'] : ['source', 'abp', 'prg', 'ppr'])));

                if ((val == undefined && (parseFloat(row.amount_correct) !== 0))

                    || ((val !== undefined) &&
                        !(that.compare(val, row, 'amount_correct') &&
                            that.compare(val, row, 'amount_obk') &&
                            that.compare(val, row, 'ind_change') &&
                            that.compare(val, row, 'ind_value') &&
                            (val.note == row.note)))) {

                    const cost = {
                        source: parseInt(row.source),
                        gr: parseInt(row.gr),
                        pgr: parseInt(row.pgr),
                        abp: parseInt(row.abp),
                        prg: parseInt(row.prg),
                        ppr: (row.type == 4 ? null : parseInt(row.ppr)),
                        name_ppi: parseFloat(row.name_ppi),
                        ind_value: parseFloat(row.ind_value),
                        amount_correct: parseFloat(row.amount_correct),
                        ind_change: parseFloat(row.ind_change),
                        amount_obk: parseFloat(row.amount_obk),
                        note: row.note,
                        region: that.region,
                        year: that.year,
                        variant: that.variant,
                        user_name: that.userLogin()
                    };
                    values.pprvals.push(cost);
                }
            }

            for (const row of that.cost.prjMap.values()) {
                const val = that.bdata.prjMap.get(that.getRowKey(row, (row.ptype == 4 ? ['source', 'abp', 'prg', 'code'] : ['source', 'abp', 'prg', 'ppr', 'code'])));

                if (((val == undefined) && (parseFloat(row.amount_correct) !== 0))

                    || ((val !== undefined) &&
                        !(that.compare(val, row, 'amount_correct') &&
                            that.compare(val, row, 'amount_obk') &&
                            that.compare(val, row, 'ind_change') &&
                            that.compare(val, row, 'ind_value') &&
                            (val.note == row.note)))) {

                    const cost = {
                        source: parseInt(row.source),
                        gr: parseInt(row.gr),
                        pgr: parseInt(row.pgr),
                        abp: parseInt(row.abp),
                        prg: parseInt(row.prg),
                        ppr: (row.ptype == 4 ? null : parseInt(row.ppr)),
                        prj: parseInt(row.code),
                        name_ppi: parseFloat(row.name_ppi),
                        ind_value: parseFloat(row.ind_value),
                        amount_correct: parseFloat(row.amount_correct),
                        ind_change: parseFloat(row.ind_change),
                        amount_obk: parseFloat(row.amount_obk),
                        note: row.note,
                        region: that.region,
                        year: that.year,
                        variant: that.variant,
                        user_name: that.userLogin()
                    };
                    values.prjvals.push(cost);
                }
            }

            return values;
        }, // сравнивает введенные данные с ранее сохраненные и формирует массив новых записей для сохранения в БД

        compare(oVal, nVal, field) {
            if ((oVal[field] == null || oVal[field] == undefined || oVal[field] == NaN) &&
                (nVal[field] == null || nVal[field] == undefined || nVal[field] == NaN)) {
                return true;
            }
            if ((oVal[field] == null || oVal[field] == undefined || oVal[field] == NaN) &&
                (nVal[field] !== null && nVal[field] !== undefined && nVal[field] !== NaN)) {
                return false;
            }
            if ((oVal[field] !== null && oVal[field] !== undefined && oVal[field] !== NaN) &&
                (nVal[field] == null || nVal[field] == undefined || nVal[field] == NaN)) {
                return false;
            }
            if (((oVal[field] !== null && oVal[field] !== undefined && oVal[field] !== NaN) &&
                (nVal[field] !== null && nVal[field] !== undefined && nVal[field] !== NaN)) &&
                (parseFloat(oVal[field]) !== parseFloat(nVal[field]))) {
                return false;
            }
            if (((oVal[field] !== null && oVal[field] !== undefined && oVal[field] !== NaN) &&
                (nVal[field] !== null && nVal[field] !== undefined && nVal[field] !== NaN)) &&
                (parseFloat(oVal[field]) == parseFloat(nVal[field]))) {
                return true;
            }
        }, // сравнение данных с разными условиями

        createTable(elem, parent_id, source) {
            const that = this;

            const item = Object.assign({}, elem);

            that.$set(item, 'visible', true);
            that.$set(item, 'source', source);
            that.$set(item, 'parent_id', parent_id);
            Object.defineProperty(item, 'parent', {
                get: function () {
                    for (const row of that.budgetForm) {
                        if ((item.source === row.source) && (item.parent_id === row.id)) {
                            return row;
                        }
                    }
                }
            });

            if (item.type > 4 || (item.type == 4 && !item.pprChild)) {

                that.$set(item, 'note', '');
                that.$set(item, 'ind_change', null);
                that.$set(item, 'ind_value', null);

                that.calcFlds.forEach((field) => {
                    that.$set(item, field, 0);
                });

                if (item.type == 6) {
                    that.cost.prjMap.set(that.getRowKey(item, (item.ppr ? ['source', 'abp', 'prg', 'ppr', 'code'] : ['source', 'abp', 'prg', 'code'])), item);
                } else {
                    that.cost.pprMap.set(that.getRowKey(item, (item.ppr ? ['source', 'abp', 'prg', 'ppr'] : ['source', 'abp', 'prg'])), item);
                }
            } else {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);

                that.calcFlds.forEach(field => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        }
                    });
                });
            }
            if ((item.type == 4 && !item.pprChild && item.child.length > 0) ||
                (item.type == 5 && item.hasOwnProperty('child') && item.child.length > 0)) {

                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);

                ['amount_correct', 'amount_obk'].forEach(field => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        }
                    });
                });
            }

            that.$set(item, 'index', that.budgetForm.length);
            that.$set(that.budgetForm, that.budgetForm.length, item);

            if (item.hasChild) {
                if (item.type == 3) {
                    item.child.sort(that.sortByField('prg'))
                }
                if (item.type == 4) {
                    item.child.sort(that.sortByField('ppr'))
                }
                if (item.type == 5) {
                    item.child.sort(that.sortByField('code'))
                }
                for (const ch of item.child) {
                    that.createTable(ch, item.id, source);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        async deleteItem(item) {
            const that = this;

            const selSource = that.selSource(item);
            that.$bvModal.msgBoxConfirm(
                'Подтвердите удаление: \"' + item.name_ru + '\" ',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        // item == prj
                        if (item.prj) {
                            const abp = selSource.filter(function (obj) {
                                if (obj.id == (item.ptype == 4 ? item.parent.parent.id : item.parent.parent.parent.id)) { return obj; }
                            });
                            const prg = abp[0].child.filter(function (obj) {
                                if (obj.id == (item.ptype == 4 ? item.parent.id : item.parent.parent.id)) { return obj; }
                            });
                            if (item.ptype == 4) {
                                that.delFromSource(prg[0].child, item);
                            } else {
                                const ppr = prg[0].child.filter(function (obj) {
                                    if (obj.id == item.parent.id) { return obj; }
                                });
                                that.delFromSource(ppr[0].child, item);
                            }
                        } else {
                            // item == abp
                            if ((item.abp !== null) && (item.prg == null) && (item.ppr == null)) {
                                that.delFromSource(selSource, item);
                            }
                            // item == prg
                            if ((item.prg !== null) && (item.ppr == null)) {
                                const abp = selSource.filter(function (obj) {
                                    if (obj.id == item.parent.id) { return obj; }
                                });
                                if (abp[0].child.length == 1) {
                                    that.delFromSource(selSource, abp[0]);
                                } else {
                                    that.delFromSource(abp[0].child, item);
                                }
                            }
                            // item == ppr
                            if (item.ppr !== null) {
                                const abp = selSource.filter(function (obj) {
                                    if (obj.id == item.parent.parent.id) { return obj; }
                                });
                                const prg = abp[0].child.filter(function (obj) {
                                    if (obj.id == item.parent.id) { return obj; }
                                });
                                if (prg[0].child.length > 1) {
                                    that.delFromSource(prg[0].child, item);
                                } else {
                                    if (abp[0].child.length == 1) {
                                        that.delFromSource(selSource, abp[0]);
                                    } else {
                                        that.delFromSource(abp[0].child, prg[0]);
                                    }
                                }
                            }
                        }
                        that.prepareDatas();
                    }
                })
                .catch(error => {
                    that.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаляет элемент

        delFromSource(arr, item) {
            arr.forEach((el, index) => {
                if (item.id == el.id) {
                    arr.splice(index, 1);
                }
            });
            this.deleteFromTable(item);
        }, // удаляет элемент из источника

        deleteFromTable(item) {
            const that = this;

            if (item.type == 6) { // удаление проекта
                const cost = {
                    gr: parseInt(item.gr),
                    pgr: parseInt(item.pgr),
                    abp: parseInt(item.abp),
                    prg: parseInt(item.prg),
                    ppr: (item.ptype == 5 ? parseInt(item.ppr): null),
                    prj: parseInt(item.code),
                    source: parseInt(item.source),
                    region: that.region,
                    year: that.year,
                    variant: that.variant
                };
                that.remove.projects.push(cost);
            }
            if (item.type == 5) {
                const cost = {
                    gr: parseInt(item.gr),
                    pgr: parseInt(item.pgr),
                    abp: parseInt(item.abp),
                    prg: parseInt(item.prg),
                    ppr: parseInt(item.ppr),
                    source: parseInt(item.source),
                    region: that.region,
                    year: that.year,
                    variant: that.variant
                };
                that.remove.values.push(cost);
            } else {
                for (const row of that.cost.pprMap.values()) {
                    if ((item.type == 3) &&
                        (row.source == item.source) &&
                        (row.abp == item.abp)) {
                        const cost = {
                            gr: parseInt(row.gr),
                            pgr: parseInt(row.pgr),
                            abp: parseInt(row.abp),
                            prg: parseInt(row.prg),
                            ppr: (row.type == 5 ? parseInt(row.ppr) : null),
                            source: parseInt(row.source),
                            region: that.region,
                            year: that.year,
                            variant: that.variant
                        };
                        that.remove.values.push(cost);
                    }
                    if ((item.type == 4) &&
                        (row.source == item.source) &&
                        (row.abp == item.abp) &&
                        (row.prg == item.prg)) {
                        const cost = {
                            gr: parseInt(row.gr),
                            pgr: parseInt(row.pgr),
                            abp: parseInt(row.abp),
                            prg: parseInt(row.prg),
                            ppr: (row.type == 5 ? parseInt(row.ppr) : null),
                            source: parseInt(row.source),
                            region: that.region,
                            year: that.year,
                            variant: that.variant
                        };
                        that.remove.values.push(cost);
                    }
                }
            }
            that.remove.mark = true;
        }, // удаляет элемент из таблицы

        async deleteDatas() {
            if (this.remove.values.length > 0) {
                const response = await fetch('/api-py/delete-cost-clarify', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.remove.values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Элемент удален');
                }
            }
            if (this.remove.projects.length > 0) {
                const response = await fetch('/api-py/delete-cost-project', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.remove.projects)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Элемент удален');
                }
            }
            this.remove = { mark: false, values: [], projects: [] };
        }, // удаляет, ранее помеченные записи из БД

        filterUpdate() {
            this.filter.search = (this.filter.abp == null ? '' : this.filter.abp) +
                (this.filter.prg == null ? '' : this.filter.prg) +
                (this.filter.ppr == null ? '' : this.filter.ppr) +
                (this.filter.name_ru == null ? '' : this.filter.name_ru);
            if (this.filter.search.length == 0) {
                this.filter.search = null;
            }
        }, // формирует строку поиска

        focusout() {
            if (!this.editing) {
                return;
            }
            const res = this.compareDatas();
            if ((!this.msg) && (res.length > 0)) {
                this.msg = true;
                this.$bvModal.msgBoxConfirm(
                    'Сохранить введенные данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'YES',
                        cancelTitle: 'NO',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            this.editing = false;
                            this.saveDatas(res);
                            this.msg = false;
                        } else {
                            this.editing = false;
                            this.msg = false;
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    });
            }
        }, // срабатывает запрос на сохранения данных, при потере фокуса области ввода

        getProject(code) {
            for (const prj of this.listProject) {
                if (prj.value.code == code) {
                    return prj.value;
                }
            }
            return null;
        }, // возвращает проект по коду

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadBudget() {
            try {
                const response = await fetch('/api-py/get-budget-data-by-yrv/' +
                    this.year + '/' + this.region + '/' + this.variant);
                this.budget = await response.json();

            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadBudget()', error.toString());
                return;
            }
        }, // данные утвержденного бюджета из BudgetData

        async loadDatas() {
            this.dictCost.splice(0);
            this.budgetForm.splice(0);

            this.cost.pprMap.clear();
            this.cost.prjMap.clear();

            this.bdata.abp.clear();
            this.bdata.pprMap.clear();
            this.bdata.prjMap.clear();

            this.saving.child.splice(0);
            this.optimization.child.splice(0);
            this.distribution.child.splice(0);
            this.redistribution.child.splice(0);

            await this.loadDict();
            this.bar = 50;
            await this.loadUserDatas();
            this.bar = 100;
        }, // загрузка данных при смене региона

        async loadDict() {
            const that = this;

            const abpMap = new Map();
            const prgMap = new Map();
            const pprMap = new Map();

            let levels = [];
            if (that.region.endsWith('0101')) {
                levels = [2];
            } else {
                levels = [3, 4];
            }

            const promise = new Promise(function (resolve, reject) {
                for (const row of that.ebkFunc.dict) {
                    that.$set(row, 'filter', row.abp +
                        (row.prg == null ? '' : that.padLeadingZeros(row.prg, 3)) +
                        (row.ppr == null ? '' : that.padLeadingZeros(row.ppr, 3)) +
                        row.name_ru);

                    if (row.type < 5) {
                        that.$set(row, 'child', []);
                    }
                    if ((row.type == 3) && (levels.includes(row.budget_level_id))) {
                        abpMap.set(that.getRowKey(row, ['abp']),
                            that.newElem('abp', row.abp, row.abp + ' - ' + row.name_ru, row));
                    }
                    if (row.type == 4) {
                        row.prg = that.padLeadingZeros(row.prg, 3);
                        prgMap.set(that.getRowKey(row, ['abp', 'prg']),
                            that.newElem('prg', row.prg, row.prg + ' - ' + row.name_ru, row));
                    }
                    if (row.type == 5) {
                        row.ppr = that.padLeadingZeros(row.ppr, 3);
                        pprMap.set(that.getRowKey(row, ['abp', 'prg', 'ppr']),
                            that.newElem('ppr', row.ppr, row.ppr + ' - ' + row.name_ru, row));
                    }
                }
                resolve({ abpMap: abpMap, prgMap: prgMap, pprMap: pprMap });
            });
            promise.then(
                result => {
                    that.ebkFunc.maps = result;
                    for (const ppr of result.pprMap.values()) {
                        const prg = result.prgMap.get(that.getRowKey(ppr.value, ['abp', 'prg']));
                        if (prg !== undefined) {
                            that.$set(prg.value, 'pprChild', true);
                            prg.value.child.push(ppr);
                        }
                    }
                    for (const prg of result.prgMap.values()) {
                        const abp = result.abpMap.get(that.getRowKey(prg.value, ['abp']));
                        if (abp !== undefined) {
                            prg.value.child.sort(that.sortByField('ppr'));
                            abp.value.child.push(prg);
                        }
                    }
                    for (const abp of result.abpMap.values()) {
                        abp.value.child.sort(that.sortByField('prg'));
                        that.dictCost.push(abp);
                    }
                    that.dictCost.sort(that.sortByField('abp'));
                },
                error => {
                    that.makeToast('danger', 'Ошибка loadDict', error.toString());
                }
            );
        }, // собирает справочник EbkFunc ввиде дерева с помощью Map-ов

        async loadEbkFunc() {
            try {
                const response = await fetch('/api-py/get-dict-func');
                this.ebkFunc.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadEbkFunc', error.toString());
            }
        }, // справочник EbkFunc

        async loadPPI() {
            try {
                const response = await fetch('/api-py/get-reestr-all/');
                const list = await response.json();

                for (const ppi of list) {
                    this.listPPI.push({
                        id: ppi.id,
                        abp: ppi.abp,
                        prg: ppi.prg,
                        ppr: ppi.ppr,
                        text: ppi.name_ru + ', ' + ppi.code
                    });
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadPPI', error.toString());
            }
        }, // список показателей результативности программ

        async loadProject() {
            try {
                const response = await fetch('/api-py/dictionary/budget_project/');
                const list = await response.json();
                list.sort(this.sortByField('code'));

                for (const prj of list) {
                    if (prj.obl == this.obl) {
                        const item = {
                            text: prj.code + ' - ' + prj.name_ru,
                            value: prj
                        }
                        this.listProject.push(item);
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadProject', error.toString());
            }
        }, // список проектов по области

        async loadUserDatas() {
            const that = this;

            let clarify = [];
            try {
                const response = await fetch('/api-py/get-cost-clarify-by-yrv/'
                    + that.year + '/' + that.region + '/' + that.variant);
                clarify = await response.json();

            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadUserDatas(clarify)', error.toString());
                return;
            }

            let project = [];
            try {
                const response = await fetch('/api-py/get-cost-project-by-yrv/'
                    + that.year + '/' + that.region + '/' + that.variant);
                project = await response.json();

            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadUserDatas(projects)', error.toString());
                return;
            }

            if (clarify.length == 0 && project.length == 0)  {
                this.bar = 100;
                return;
            }

            const abpMap = new Map();
            const prgMap = new Map();
            const pprMap = new Map();
            const user = that.userLogin();

            const promise = new Promise(function (resolve, reject) {
                for (const val of clarify) {
                    that.bdata.abp.add(val.abp);
                    that.bdata.pprMap.set(that.getRowKey(val, (val.ppr == null ? ['source', 'abp', 'prg'] : ['source', 'abp', 'prg', 'ppr'])), val);

                    if (val.user_name == user) {
                        const a = that.ebkFunc.maps.abpMap.get(that.getRowKey(val, ['abp']));
                        if (a !== undefined) {
                            const abp = Object.assign({}, a.value);
                            that.$set(abp, 'child', []);
                            that.$set(abp, 'source', val.source);
                            abpMap.set(that.getRowKey(val, ['source', 'abp']), abp);
                        }

                        const pr = that.ebkFunc.maps.prgMap.get(that.getRowKey(val, ['abp', 'prg']));
                        if (pr !== undefined) {
                            const prg = Object.assign({}, pr.value);
                            that.$set(prg, 'child', []);
                            that.$set(prg, 'source', val.source);
                            prgMap.set(that.getRowKey(val, ['source', 'abp', 'prg']), prg);
                        }

                        const pp = that.ebkFunc.maps.pprMap.get(that.getRowKey(val, ['abp', 'prg', 'ppr']));
                        if (pp !== undefined) {
                            const ppr = Object.assign({}, pp.value);
                            that.$set(ppr, 'source', val.source);
                            pprMap.set(that.getRowKey(val, ['source', 'abp', 'prg', 'ppr']), ppr);
                        }
                    }
                }
                resolve({ abpMap: abpMap, prgMap: prgMap, pprMap: pprMap });
            });
            promise.then(
                result => {
                    for (const val of project) {
                        that.bdata.prjMap.set(that.getRowKey(val, (val.ppr == null ? ['source', 'abp', 'prg', 'prj'] : ['source', 'abp', 'prg', 'ppr', 'prj'])), val);
                        const prj = that.getProject(val.prj);
                        if (val.ppr == null) {
                            const prg = result.prgMap.get(that.getRowKey(val, ['source', 'abp', 'prg']));
                            if (prg !== undefined && prj !== null) {
                                prg.child.push(that.addProject(prg, prj));
                            }
                        } else {
                            const ppr = result.pprMap.get(that.getRowKey(val, ['source', 'abp', 'prg', 'ppr']));
                            if (ppr !== undefined && prj !== null) {
                                if (!ppr.hasOwnProperty('child')) {
                                    that.$set(ppr, 'child', []);
                                }
                                ppr.child.push(that.addProject(ppr, prj));
                            }
                        }
                    }

                    for (const ppr of result.pprMap.values()) {
                        const prg = result.prgMap.get(that.getRowKey(ppr, ['source', 'abp', 'prg']));
                        if (prg !== undefined) {
                            prg.child.push(ppr);
                        }
                    }
                    for (const prg of result.prgMap.values()) {
                        const abp = result.abpMap.get(that.getRowKey(prg, ['source', 'abp']));
                        if (abp !== undefined) {
                            abp.child.push(prg);
                        }
                    }
                    for (const abp of result.abpMap.values()) {
                        const selSource = that.selSource(abp);
                        selSource.push(abp);
                    }
                    that.prepareDatas();
                },
                error => {
                    that.makeToast('danger', 'Ошибка loadDict', error.toString());
                }
            );
        }, // формирует дерево из последних данных, введенных данным пользователем

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        newElem(field, code, text, value) {
            const el = {};
            this.$set(el, field, code);
            this.$set(el, 'text', text);
            this.$set(el, 'value', value);
            return el;
        }, // формирует элемент для отображения в фильтрах

        noAbc: function (evt) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            // /^[0-9]+([.][0-9]+)?$/
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
            if (!regex.test(key)) {
                evt.preventDefault();
                return false;
            }
        }, // вводит только цифры

        openAll() {
            this.open = !this.open;

            for (const row of this.budgetForm) {
                if (row.type > 0) {
                    row.visible = this.open;
                    if ([3, 4, 5].includes(row.type)) {
                        row.open = this.open;
                    }
                    if ([0, 3].includes(row.type)) {
                        row.visible = true;
                    }
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent) {
            parent.open = !parent.open;

            for (const row of this.budgetForm) {
                if (parent.source === row.source && parent.id === row.parent_id) {
                    row.visible = parent.open;
                    if (row.hasChild && row.open !== parent.open) {
                        this.openChilds(row);
                    }
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        ppi(item) {
            const res = [];
            for (const p of this.listPPI) {
                if (item.ppr == null && p.ppr == null && p.abp == item.abp && p.prg == item.prg) {
                    const pp = Object.assign({}, p);
                    res.push(pp);
                }

                if (item.ppr !== null && p.abp == item.abp && p.prg == item.prg && p.ppr == item.ppr) {
                    const pp = Object.assign({}, p);
                    res.push(pp);
                }
            }
            if (res.length == 1) {
                item.name_ppi = res[0].id;
            }
            return res;
        }, // выборка PPI для БП и БПП

        async prepareDatas() {
            const that = this;

            that.budgetForm.splice(0);
            const promise = new Promise(function (resolve, reject) {

                for (const item of that.listSrc) {
                    item.value.child.sort(that.sortByField('abp'));
                    that.createTable(item.value, 0, item.value.id);
                    that.bar += 10;
                }

                resolve(true);
            });
            promise.then(
                result => {
                    that.relevant();
                    that.open = result;
                    that.openAll();

                    if (that.selectedABP.id > 0) {
                        for (const row of that.budgetForm) {
                            if ((row.source == that.curSource.id) &&
                                (row.abp == that.selectedABP.abp) &&
                                (row.type == 3)) {
                                row.open = false;
                                that.openChilds(row);

                                if (that.active !== null) {
                                    const input = document.getElementById(that.active);
                                    if (input !== null) {
                                        input.select();
                                    }
                                    that.active = null;
                                }
                                return;
                            }
                        }
                    }
                },
                error => {
                    that.makeToast('danger', 'Ошибка loadDict', error.toString());
                }
            );
        }, // подготовка отображения данных

        async relevant() {
            const that = this;

            // выставляем значения утвержденного бюджета
            await that.budget.forEach(val => {
                for (const source of that.listSrc) {
                    const ppr = that.cost.pprMap.get(that.padLeadingZeros(source.value.id, 3) + '.'
                        + that.getRowKey(val, (val.ppr == null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr'])));
                    if (ppr !== undefined) {
                        that.$set(ppr, 'budget', val.value);
                    }
                }
            });

            if ((that.selectedABP.id > 0) && !(that.bdata.abp.has(that.selectedABP.abp))) {
                try {
                    const response = await fetch('/api-py/get-cost-clarify-by-yrvabp/'
                        + that.year + '/' + that.region + '/' + that.variant + '/'
                        + that.selectedABP.abp);
                    const values = await response.json();

                    for (const val of values) {
                        that.bdata.pprMap.set(that.getRowKey(val, (val.ppr == null ? ['source', 'abp', 'prg'] : ['source', 'abp', 'prg', 'ppr'])), val);
                    }
                } catch (error) {
                    that.makeToast('danger', 'Ошибка запроса данных relevant()', error.toString());
                    return;
                }
            }

            // выставляем значения расходов
            for (const ppr of that.cost.pprMap.values()) {
                const val = that.bdata.pprMap.get(that.getRowKey(ppr, (ppr.type == 4 ? ['source', 'abp', 'prg'] : ['source', 'abp', 'prg', 'ppr'])));
                if (val !== undefined) {
                    that.$set(ppr, 'name_ppi', val.name_ppi);
                    that.$set(ppr, 'ind_value', val.ind_value);
                    that.$set(ppr, 'ind_change', val.ind_change);
                    that.$set(ppr, 'note', val.note);
                    if (!ppr.hasChild) {
                        that.$set(ppr, 'amount_correct', val.amount_correct);
                        that.$set(ppr, 'amount_obk', val.amount_obk);
                    }
                }
            }

            // выставляем значения проектов
            for (const prj of that.cost.prjMap.values()) {
                const val = that.bdata.prjMap.get(that.getRowKey(prj, (prj.ptype == 4 ? ['source', 'abp', 'prg', 'code'] : ['source', 'abp', 'prg', 'ppr', 'code'])));
                if (val !== undefined) {
                    that.$set(prj, 'name_ppi', val.name_ppi);
                    that.$set(prj, 'ind_value', val.ind_value);
                    that.$set(prj, 'ind_change', val.ind_change);
                    that.$set(prj, 'note', val.note);
                    that.$set(prj, 'amount_correct', val.amount_correct);
                    that.$set(prj, 'amount_obk', val.amount_obk);
                }
            }
        }, // расставляет сохранненные данные по полям

        reSum(parent, field) {
            let sum = 0;
            this.budgetForm.forEach(row => {
                if (parent.source == row.source && parent.id == row.parent_id) {
                    sum += parseFloat(row[field]);
                }
            });
            return parseFloat(sum.toFixed(2));
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') { return; }
            if (!item.visible) { return 'is-hidden'; }
        }, // задает класс 'is-hidden' заданной строке

        saveDatas(res) {
            if (res == null) {
                res = this.compareDatas();
            }
            if (res.pprvals.length > 0) {
                this.savePPR(res.pprvals);
            }
            if (res.prjvals.length > 0) {
                this.savePRJ(res.prjvals);
            }
        }, // вызывает сохранение записей

        async savePRJ(values) {
            const that = this;
            const response = await fetch('/api-py/save-cost-project', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });
            const result = await response.json();
            if ((response.status == 200) && (result.result == 'success')) {
                for (const val of values) {
                    const obj = Object.assign({}, val);
                    that.bdata.prjMap.set(that.getRowKey(obj, (val.ppr == null ? ['source', 'abp', 'prg', 'prj'] : ['source', 'abp', 'prg', 'ppr', 'prj'])), obj);
                }
                that.makeToast('success', 'Сообщение', 'Проекты сохранены');
            } else {
                that.makeToast('danger', 'Ошибка сохранения savePRJ', result.result);
            }
            return response;
        }, // сохранение проектов в БД


        async savePPR(values) {
            const that = this;
            const response = await fetch('/api-py/save-cost-clarify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });
            const result = await response.json();
            if ((response.status == 200) && (result.result == 'success')) {
                for (const val of values) {
                    const obj = Object.assign({}, val);
                    that.bdata.pprMap.set(that.getRowKey(obj, (val.ppr == null ? ['source', 'abp', 'prg'] : ['source', 'abp', 'prg', 'ppr'])), obj);
                }
                that.makeToast('success', 'Сообщение', 'Программы сохранены');
            } else {
                that.makeToast('danger', 'Ошибка сохранения savePPR', result.result);
            }
            return response;
        }, // сохранение программ в БД

        selSource(item) {
            switch (item.source) {
                case 1:
                    return this.saving.child;
                case 2:
                    return this.optimization.child;
                case 3:
                    return this.distribution.child;
                case 4:
                    return this.redistribution.child;
            }
        }, // определяет источник выбранного элемента item

        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }, // сортирует по заданному полю

        userLogin() { //
            return store.state.user.login;
        } // имя пользвателя
    }
};
</script>
